module.exports = {
  // app info (copied from the FusionAuth admin panel)
  applicationId: 'e6a4b1ff-d350-4df5-9247-79a51ab76f69',
  tenantId: '7414e3cc-cb60-4967-93d3-21e8bd306a78',
  // our FusionAuth api key
  apiKey: 'S4mz8wT12bSpo1u5N72ec6I3iE-2-5Lj1-N5c5qAAUrJfgP1Pl9YitAz',
  SendReminderToPlayer: '40435fd8-e255-47aa-8f28-b1b74ae2663c',

  // ports
  clientPort: 8080,
  serverPort: 9000,
  fusionAuthPort: 9011,

  apiServer: 'https://bearislands.com/api/',
  fusionAuthServer: 'http://localhost:9011',

  mongoInstance: 'mongodb://localhost:27017/',
  mongoDatabase: 'contactBearisland',

  websiteName: 'bearislands.com',
  PRODUCTION: true
};
